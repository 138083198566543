import Vue from 'vue'
import Router from 'vue-router'
import VueTabs from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import i18n from '../i18n'
// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const DailySales = () => import('@/components/DailySales.vue')
const MonthlySales = () => import('@/components/MonthlySales.vue')
const YearlySales = () => import('@/components/YearlySales.vue')
const Loading = () => import('@/views/Loading')

// Views - Pages
const Login = () => import('@/views/Login')

// Jobs
const Jobs = () => import('@/views/jobs/Index')
const CreateJob = () => import('@/views/jobs/Create')
const EditJob = () => import('@/views/jobs/Edit')
const ShowJob = () => import('@/views/jobs/Show')

// Products
const Products = () => import('@/views/products/Index')
const CreateProduct = () => import('@/views/products/Create')
const EditProduct = () => import('@/views/products/Edit')
const ShowProduct = () => import('@/views/products/Show')

// Expenses
const Expenses = () => import('@/views/expenses/Index')
const CreateExpense = () => import('@/views/expenses/Create')
const EditExpense = () => import('@/views/expenses/Edit')
const ShowExpense = () => import('@/views/expenses/Show')

// Customers
const Customers = () => import('@/views/customers/Index')
const CreateCustomer = () => import('@/views/customers/Create')
const UpdateCustomer = () => import('@/views/customers/Edit')
const ShowCustomer = () => import('@/views/customers/Show')
const printCustomer = () => import('@/views/customers/printCustomer')
const UpdateProductPrice = () => import('@/views/customers/EditProductPrice')

// Customers Accounts
const CustomersAccounts = () => import('@/views/customers-accounts/Index')
const CreateCustomerAccount = () => import('@/views/customers-accounts/Create')
const UpdateCustomerAccount = () => import('@/views/customers-accounts/Edit')
const ShowCustomerAccount = () => import('@/views/customers-accounts/Show')

// categories
const Categories = () => import('@/views/categories/Index')

// roles
const Roles = () => import('@/views/roles/Index')
const CreateRole = () => import('@/views/roles/Create')
const UpdateRole = () => import('@/views/roles/Edit')
const ShowRole = () => import('@/views/roles/Show')

// users
const Users = () => import('@/views/users/Index')
const CreateUser = () => import('@/views/users/Create')
const UpdateUser = () => import('@/views/users/Edit')
const ShowUser = () => import('@/views/users/Show')
// offices
const Offices = () => import('@/views/offices/Index')
const CreateOffice = () => import('@/views/offices/Create')
const UpdateOffice = () => import('@/views/offices/Edit')
const ShowOffice = () => import('@/views/offices/Show')

// reports
const InventoryReports = () => import('@/views/reports/InventoryReports')
// const DistributorsReports = () => import('@/views/reports/DistributorsReports')
const CommissionReports = () => import('@/views/reports/CommissionReports')
const AccountingReports = () => import('@/views/reports/AccountingReports')
// const Stocks = () => import('@/views/reports/stocks')
const Payroll = () => import('@/views/reports/payroll')
const OrderReports = () => import('@/views/reports/OrdersReport')
const AnalysisReport = () => import('@/views/reports/AnalysisReport')
const ExpensesReports = () => import('@/views/reports/ExpensesReports')
const CustomerReports = () => import('@/views/reports/CustomerReport')
const SalesReport = () => import('@/views/reports/SalesReport')
const SalesPercentChart = () => import('@/views/reports/SalesPercentChart')

// suppliers
const Suppliers = () => import('@/views/suppliers/Index')
const CreateSupplier = () => import('@/views/suppliers/Create')
const EditSupplier = () => import('@/views/suppliers/Edit')
const ShowSupplier = () => import('@/views/suppliers/Show')

// Employees
const Employees = () => import('@/views/employees/Index')
const CreateEmployee = () => import('@/views/employees/Create')
const EditEmployee = () => import('@/views/employees/Edit')
const ShowEmployee = () => import('@/views/employees/Show')

// PurchaseInvoices
const PurchaseInvoices = () => import('@/views/purchase-invoices/Index')
const CreatePurchaseInvoices = () => import('@/views/purchase-invoices/Create')
const EditPurchaseInvoices = () => import('@/views/purchase-invoices/Edit')
const ShowPurchaseInvoices = () => import('@/views/purchase-invoices/Show')

// SalesInvoices
const SalesInvoices = () => import('@/views/sales/Index')
const CreateSalesInvoices = () => import('@/views/sales/Create')
const createOfficePayment = () => import('@/views/sales/createOffice')
const EditSalesInvoices = () => import('@/views/sales/Edit')
const ShowSalesInvoices = () => import('@/views/sales/Show')

// Orders
const Orders = () => import('@/views/orders/Index')
const CreateOrders = () => import('@/views/orders/Create')
const EditOrders = () => import('@/views/orders/Edit')
const ShowOrders = () => import('@/views/orders/Show')
// export
const ExportData = () => import('@/views/export/exportData')
Vue.use(Router)
Vue.use(VueTabs)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {

      path: '/',
      redirect: '/loading',
      name: i18n.t('message.main'),
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: i18n.t('message.dashboard'),
          component: Dashboard
        },
        //  Stocks
        {
          path: 'stocks',
          meta: {
            label: i18n.t('message.stocks')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          }
        },
        // Customers
        {
          path: 'customers',
          meta: {
            label: i18n.t('message.customers')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.customers'),
              component: Customers
            },
            {
              name: i18n.t('message.updateCustomer'),
              path: ':id/edit',
              component: UpdateCustomer
            },
            {
              path: 'create',
              name: i18n.t('message.createCustomer'),
              component: CreateCustomer
            },
            {
              path: ':id/show',
              name: i18n.t('message.showCustomer'),
              component: ShowCustomer
            },
            {
              path: ':id/print',
              name: i18n.t('message.print'),
              component: printCustomer
            },
            {
              path: ':id/edit-price',
              name: i18n.t('message.updateProductPrice'),
              component: UpdateProductPrice
            }

          ]
        },
        // Customers Accounts
        {
          path: 'customers-accounts',
          meta: {
            label: i18n.t('message.customersAccounts')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.customersAccounts'),
              component: CustomersAccounts
            },
            {
              name: i18n.t('message.updateCustomerAccount'),
              path: ':id/edit',
              component: UpdateCustomerAccount
            },
            {
              path: 'create',
              name: i18n.t('message.createCustomerAccount'),
              component: CreateCustomerAccount
            },
            {
              path: ':id/show',
              name: i18n.t('message.showCustomerAccount'),
              component: ShowCustomerAccount
            }

          ]
        },
        // categories
        {
          path: 'categories',
          meta: {
            label: i18n.t('message.categories')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.categories'),
              component: Categories
            }

          ]
        },
        // roles
        {
          path: 'roles',
          meta: {
            label: i18n.t('message.role')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.role'),
              component: Roles
            },
            {
              name: i18n.t('message.updateRole'),
              path: ':id/edit',
              component: UpdateRole
            },
            {
              path: 'create',
              name: i18n.t('message.createRole'),
              component: CreateRole
            },
            {
              path: ':id/show',
              name: i18n.t('message.showRole'),
              component: ShowRole
            }

          ]
        },
        // users
        {
          path: 'users',
          meta: {
            label: i18n.t('message.users')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.users'),
              component: Users
            },
            {
              name: i18n.t('message.updateUser'),
              path: ':id/edit',
              component: UpdateUser
            },
            {
              path: 'create',
              name: i18n.t('message.createUser'),
              component: CreateUser
            },
            {
              path: ':id/show',
              name: i18n.t('message.showUser'),
              component: ShowUser
            }

          ]
        },
        // offices
        {
          path: 'offices',
          meta: {
            label: i18n.t('message.offices')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.offices'),
              component: Offices
            },
            {
              name: i18n.t('message.updateOffice'),
              path: ':id/edit',
              component: UpdateOffice
            },
            {
              path: 'create',
              name: i18n.t('message.createOffice'),
              component: CreateOffice
            },
            {
              path: ':id/show',
              name: i18n.t('message.showOffice'),
              component: ShowOffice
            }

          ]
        },
        // reports
        {
          path: 'reports',
          // meta: {
          //   label: i18n.t('message.reports')
          // },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'daily-sales',
              name: i18n.t('message.dailySales'),
              component: DailySales
            },
            {
              path: 'monthly-sales',
              name: i18n.t('message.monthlySales'),
              component: MonthlySales
            },
            {
              path: 'yearly-sales',
              name: i18n.t('message.yearlySales'),
              component: YearlySales
            },
            {
              path: 'payroll',
              name: i18n.t('message.payroll'),
              component: Payroll
            },
            {
              name: i18n.t('message.inventoryReports'),
              path: 'inventory-reports',
              component: InventoryReports
            },
            {
              name: i18n.t('message.customersAccounts'),
              path: 'commission-reports',
              component: CommissionReports
            },
            {
              name: i18n.t('message.accountingReports'),
              path: 'accounting-reports',
              component: AccountingReports
            },
            {
              name: i18n.t('message.ordersReports'),
              path: 'orders-reports',
              component: OrderReports
            },
            {
              name: i18n.t('message.analysisReport'),
              path: 'analysis-reports',
              component: AnalysisReport
            },
            {
              name: i18n.t('message.expensesReports'),
              path: 'expenses-reports',
              component: ExpensesReports
            },
            {
              name: i18n.t('message.customerReports'),
              path: 'customer-reports',
              component: CustomerReports
            },
            {
              name: i18n.t('message.salesReport'),
              path: 'sales-report',
              component: SalesReport
            },
            {
              name: i18n.t('message.salesPercentChart'),
              path: 'sales-percent-chart',
              component: SalesPercentChart
            }

          ]
        },
        // jobs
        {
          path: 'jobs',
          meta: {
            label: 'الوظائف'
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'الوظائف',
              component: Jobs
            },
            {
              name: 'تعديل وظيفة',
              path: ':id/edit',
              component: EditJob
            },
            {
              path: 'create',
              name: 'إضافة وظيفة  جديدة',
              component: CreateJob
            },
            {
              path: ':id/show',
              name: 'عرض الوظيفة ',
              component: ShowJob
            }

          ]
        },
        // suppliers
        {
          path: 'suppliers',
          meta: {
            label: 'الموردين'
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'المُوردين',
              component: Suppliers
            },
            {
              name: 'تعديل المُورد',
              path: ':id/edit',
              component: EditSupplier
            },
            {
              path: 'create',
              name: 'إضافة  مُورد  جديد',
              component: CreateSupplier
            },
            {
              path: ':id/show',
              name: 'عرض  المُورد',
              component: ShowSupplier
            }
          ]
        },
        // products
        {
          path: 'products',
          meta: {
            label: i18n.t('message.products')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.products'),
              component: Products
            },
            {
              name: i18n.t('message.updateProduct'),
              path: ':id/edit',
              component: EditProduct
            },
            {
              path: 'create',
              name: i18n.t('message.createProduct'),
              component: CreateProduct
            },
            {
              path: ':id/show',
              name: i18n.t('message.showProduct'),
              component: ShowProduct
            }
          ]
        },
        // expenses
        {
          path: 'expenses',
          meta: {
            label: i18n.t('message.expenses')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.expenses'),
              component: Expenses
            },
            {
              name: i18n.t('message.updateExpense'),
              path: ':id/edit',
              component: EditExpense
            },
            {
              path: 'create',
              name: i18n.t('message.createExpense'),
              component: CreateExpense
            },
            {
              path: ':id/show',
              name: i18n.t('message.showExpense'),
              component: ShowExpense
            }
          ]
        },
        // export
        {
          path: 'export',
          meta: {
            label: i18n.t('message.export')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: '',
              component: ExportData
            }
          ]
        },
        // Employees
        {
          path: 'employees',
          meta: {
            label: i18n.t('message.employees')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.employees'),
              component: Employees
            },
            {
              path: 'create',
              name: i18n.t('message.createEmployee'),
              component: CreateEmployee
            },
            {
              name: i18n.t('message.updateEmployee'),
              path: ':id/edit',
              component: EditEmployee
            },
            {
              name: i18n.t('message.showEmployee'),
              path: ':id/show',
              component: ShowEmployee
            }
          ]
        },
        {
          path: 'orders',
          meta: {
            label: i18n.t('message.order')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.order'),
              component: Orders
            },
            {
              path: 'create',
              name: i18n.t('message.createOrder'),
              component: CreateOrders
            },
            {
              name: i18n.t('message.updateOrder'),
              path: ':id/edit',
              component: EditOrders
            },
            {
              name: i18n.t('message.showOrder'),
              path: ':id/show',
              component: ShowOrders
            }
          ]
        },
        // sales invoices
        {
          path: 'payments',
          meta: {
            label: i18n.t('message.paymentInvoices')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.paymentInvoices'),
              component: SalesInvoices
            },
            {
              path: 'create',
              name: i18n.t('message.createPaymentInvoices'),
              component: CreateSalesInvoices
            },
            {
              path: 'create-office-payment',
              name: i18n.t('message.createPaymentInvoices'),
              component: createOfficePayment
            },
            {
              name: i18n.t('message.updateSalesInvoices'),
              path: ':id/edit',
              component: EditSalesInvoices
            },
            {
              name: i18n.t('message.showSalesInvoices'),
              path: ':id/show',
              component: ShowSalesInvoices
            }
          ]
        },
        // purchase invoices
        {
          path: 'purchase-invoices',
          meta: {
            label: i18n.t('message.purchaseInvoices')
          },
          component: {
            render (c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: i18n.t('message.purchaseInvoices'),
              component: PurchaseInvoices
            },
            {
              path: 'create',
              name: i18n.t('message.createPurchaseInvoices'),
              component: CreatePurchaseInvoices
            },
            {
              name: i18n.t('message.updatePurchaseInvoices'),
              path: ':id/edit',
              component: EditPurchaseInvoices
            },
            {
              name: i18n.t('message.showPurchaseInvoices'),
              path: ':id/show',
              component: ShowPurchaseInvoices
            }
          ]
        }
      ]
    },
    // orders
    {
      path: 'orders',
      meta: {
        label: i18n.t('message.orders')
      },
      component: {
        render (c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '',
          name: i18n.t('message.orders'),
          component: Orders
        },
        {
          path: 'create/purchase',
          name: i18n.t('message.createOrder'),
          component: CreateOrders
        },
        {
          name: i18n.t('message.editOrder'),
          path: ':id/edit',
          component: EditOrders
        }
      ]
    },
    {
      path: '/login',
      component: Login,
      name: 'Login'
    },
    {
      path: '/loading',
      component: Loading,
      name: 'Loading'
    }
  ]
}
